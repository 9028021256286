<template>
    <div style="height:100%; display: flex; ">
        <div class="layout-set">
            <div>屏幕设置</div>
            <div class="set-list">
                <div @click="videoCount = 2" :class="videoCount == 2 ? 'checked-set' : ''"><img :src="videoCount == 2?require('@/assets/ic_two_sel.png'):require('@/assets/ic_two_nor.png')" alt="" /></div>
                <div @click="videoCount = 4" :class="videoCount == 4 ? 'checked-set' : ''"><img :src="videoCount == 4?require('@/assets/ic_four_sel.png'):require('@/assets/ic_four_nor.png')" alt="" /></div>
                <div @click="videoCount = 6" :class="videoCount == 6 ? 'checked-set' : ''"><img :src="videoCount == 6?require('@/assets/ic_six_sel.png'):require('@/assets/ic_six_nor.png')" alt="" /></div>
            </div>
        </div>
        <SideLayout ref="sideRef" @onExpand="onLayoutExpand">
            <template v-slot:left-content>
                <ElevatorSelector @changeElevators="updateCheckedElevators" :videoCount="videoCount" :from="'multiple'" />
            </template>
            <template v-slot:right-content>
                <div id="right-wrapper" class="right-content-wrapper" v-if="showVideo">
                    <div class="animation-bottom" :class="videoCount == 2 ? '' : 'animation-bottom-wrap'">
                        <div class="animation-item" :class="videoCount == 2 ? 'animation-item-1' : (videoCount == 4 ? 'animation-item-2' : '')" v-for="n in videoCount">
                            <ElevatorVideo :num="n" :key="n" :elevator="selectedElevators[n-1]"/>
                        </div>
                    </div>
                </div>
            </template>
        </SideLayout>
    </div>
</template>
<script>
import SideLayout from '@/components/sideLayout'
import ElevatorSelector from '../components/elevatorSelector'
import ElevatorVideo from '../components/elevatorVideo'
import videoKit from '@/common/utils/videoKit/index'

export default {
    data() {
        return {
            showVideo: true,
            expand: false,
            videoCount: 2,
            selectedElevators: [],
        }
    },
    components: {
        SideLayout,
        ElevatorSelector,
        ElevatorVideo
    },
    mounted() {
        this.$refs.sideRef.updateExpand()
    },

    methods: {
        onLayoutExpand(expand) {
            this.expand = expand
        },
        updateCheckedElevators(elevators) {
           // elevators = elevators.filter((elevator)=> {
           //      return elevator != null
           //  })
            this.selectedElevators = elevators;
        }
    },
    watch: {
        videoCount(newData, oldData) {
            if (newData != oldData) {
                this.selectedElevators = []
                this.showVideo = false
                this.$nextTick(() => {
                    this.showVideo = true
                })
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/common/styles/handle.scss";
.right-content-wrapper {
    width: 100%;
    height: 98%;
    margin-top: 10px;
    margin-left: 6px;
    @include background_color(multipleMonitorRightContainerBgColor);
}

.animation-bottom {
    height: 98.9%;
    display: flex;
    padding: 3px;
    gap: 1.5px;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    @include background_color(multipleMonitorRightContainerBgColor);
}

.animation-item {
    width: 49.9%;
    height: 33.333333%;
}

.animation-item-1 {
    width: 80%;
    height: 50%;
}

.animation-item-2 {
    width: 49.9%;
    height: 40%;
}

.layout-set {
    width: 5%;
    height: 100%;
    //margin-right: 10px;
    @include background_color(layoutSetBgColor);
    @include font_color(layoutSetTextColor);
    padding-top: 16px;
    text-align: center;
    font-weight: 700;
}

.set-list {
    width: 100%;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
}

.set-list div {
    width: 68%;
    height: 56px;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
}

.checked-set {
    background-color: RGBA(0, 123, 229, 1);
}

.set-list img {
    width: 36px;
    height: 36px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
</style>